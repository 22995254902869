import { xyz_n_1of6_lists, xyz_n_1of6, xyz_2_2of6, checklistAnnouncementsPreworkAndAgenda, completeDeck, tReviewDemoSchedule, tLab } from './SL00000Sprint00'
import { basicSlide, breakoutStandard, orderedListSlide, submissionPercentage, tPrework, tQuizExpectations, tQuiz, tRecap, sprintDemosIntro, sprintDemos, demoAssignment } from './SLSprint00'

import { list20000Sprint01 } from '../ActivityLists/AL20000Sprint01'
import { list20000Sprint02 } from '../ActivityLists/AL20000Sprint02'
import { ics_3_1of6_PAaA } from './SL20000Sprint03'

// Introduction to Computer Science (ICS) sprint 2 shared values.
const sprint = 2
const activityListPrevious = () => { return list20000Sprint01(sprint-1) }
const activityList = () => { return list20000Sprint02(sprint) }

// Session 1 of 6: Monday
export const xyz_2_1of6PlanningExpectations = () => {
	return orderedListSlide('Sprint Planning', `Sprint ${sprint} Expectations:`, [
		'Similar to previous sprint with Discussion, Quiz, Lab, and Reflection',
		'We will complete our scrum team Discussion Board breakout session on Wednesday',
		`We will have sprint ${sprint-1} Demos and Retrospective on Friday`,
		`Sprint ${sprint} should be a little easier since we don’t have a holiday during the sprint` ])
}

export const ics_2_1of6_PAaA = xyz_n_1of6_lists(sprint)
export const ics_2_1of6 = () => { return xyz_n_1of6(sprint, ics_2_1of6_PAaA.prework, activityListPrevious, ics_2_1of6_PAaA.agenda, ics_2_2of6_PAaA.prework, activityList, xyz_2_1of6PlanningExpectations) }

// Session 2 of 6: Wednesday
const ics_2_2of6_PAaA = {
	'prework': [
		'Complete through activity 6 prior to next class', '',
		'Be prepared for Discussion  & Lab Wednesday',
		'Demos and retrospectives will be Friday' ],
	'announcements':[
		'ECaMS study table link is updated',
		'Reducing procrastination is on the agenda',
		'Does everyone know where to find class recordings?'],
	'agenda':[
		`Sprint ${sprint} Planning Questions & Answers`,
		`Discussion Board ${sprint} as a scrum team`,
		'Confirm Demos for Next Class',
		'Lab',
		'Prework' ]
}
export const ics_2_2of6 = () => { return xyz_2_2of6(sprint, activityList, ics_2_2of6_PAaA, ics_2_3of6_PAaA) }

// Session 3 of 6: Friday
const ics_2_3of6_PAaA = {
	'prework': [
		'Complete through activity 10 prior to next class', '',
		'Be prepared to discuss “The Information Layer”' ],
	'announcements':[ 
		'Any announcements?' ],
	'agenda':[
		`Sprint ${sprint-1} Demos`,
		`Sprint ${sprint-1} Retrospective`,
		`Breakout for Sprint ${sprint-1} Retrospective`,
		'Prework for Next Class' ]
}
export const ics_2_3of6 = () => { 
	// Todo: Add slide for Metrics (Rule #9)... “Anything that is measured and watched, improves.” – Bob Parsons
	// Todo: Consider adding the pretty slides back into slide deck for Demos and Retrospectives. 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_2_3of6_PAaA, sprint, activityList)
	const metrics = () => {
		return basicSlide(`Sprint ${sprint-1} Metrics`, [
			'What is Bob Parsons Rule #9?', '',
			`Let’s take a minute and review our Sprint ${sprint-1} Submission Percentage class metric.` ])
	}
	const metricsSubmissionPercentage = () => {
		return submissionPercentage([
			{ name: 'Discussion', due:21, submitted:19 },
			{ name: 'Quiz', due:21, submitted:20 },
			{ name: 'Lab', due:21, submitted:19 },
			{ name: 'Reflection', due: 21, submitted: 20 }])
	}

	const retrospective = () => {
		return orderedListSlide('Class Retrospective',
			'Feedback from Assignments & Reflections', [
			'Not a great submission percentage in sprint 1',
			'Treats for perfect 100% submission percentage',
			'All assignments are graded and posted', 
			'Thank you for your reflection comments',
			'Wonderful comments about teammates and working with them... you are appreciated',
			'Lots of nice comments activities... challenging but fun', 
			'Be sure to put something in for each question so that I can give you at least a point or two',
			'As alway numerous comments about starting late, cramming at the last minute, and struggling' ])
	}
	const retrospectiveBreakout = () => {
		return orderedListSlide('Breakout Session for Team Retrospective', 
			'As a scrum team consider:', [
			`How does your team feel about sprint ${sprint-1} now that it is over`,
			`What could be done to make sprint ${sprint-1} or the class overall better or more manageable`,
			'What improvements should we make as a class, team, or individual going forward' ])
	}
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_2_4of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ tReviewDemoSchedule, sprintDemosIntro, sprintDemos, demoAssignment, metrics, 
		metricsSubmissionPercentage, retrospective, retrospectiveBreakout, preworkNext, tLab ])
}

// Session 4 of 6: Monday
const breakoutTheBigPictureName = 'The Big Picture'
const breakoutTheBigPicture = () => { 
	return breakoutStandard( 
		`Breakout: ${breakoutTheBigPictureName}`, 
		`In this breakout session on our ${breakoutTheBigPictureName} reading and lecture your team will discuss:`, [
		'History', 
		'Abstraction', 
		'Data & Information',
		'Programming',
		'The Internet & The Web',
		'Artificial Intelligence' ])
}

const ics_2_4of6_PAaA = {
	'prework': [
		'Complete through activity 11 prior to next class', '',
		`Be prepared for ${breakoutTheBigPictureName} breakout` ],
	'announcements':[ 
		'Any announcements or questions?' ],
	'agenda':[ 
		'Sprint Progress Polling',
		`Breakout: ${breakoutTheBigPictureName}`,
		'Prework for Next Class',
		'Lab' ]
}
export const ics_2_4of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_2_4of6_PAaA, sprint, activityList)
	const poll = () => { return tPrework('Sprint Progress Polling', ics_2_4of6_PAaA.prework, sprint, activityList) }
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_2_5of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ poll, breakoutTheBigPicture, tLab, preworkNext ])
}

const breakoutNumberingSystemsName = 'Numbering Systems'
const breakoutNumberingSystems = () => { 
	return breakoutStandard(
		`Breakout: ${breakoutNumberingSystemsName}`, 
		`In this breakout session on ${breakoutNumberingSystemsName} your team will discuss:`, [
		'Numbers and Computing', 
		'Positional Notation', 
		'Binary, Octal, and Hexadecimal',
		'Why Hex? How does this relate to files?',
		'What about conversion calculators?' ])
}

// Session 5 of 6: Wednesday
const ics_2_5of6_PAaA = {
	'prework': [
		'Complete through activity 13 prior to next class', '',
		`Be prepared for ${breakoutNumberingSystemsName} breakout`,
		`Be prepared for Quiz ${sprint}` ],
	'announcements':[ 
		'Friday is remote via Zoom',
		'All sprint 2 assignments are due Sunday!' ],
	'agenda':[ 
		'Sprint Progress Polling',
		`Breakout: ${breakoutNumberingSystemsName}`,
		'Lab',
		`Quiz ${sprint}` ],
}

export const ics_2_5of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_2_5of6_PAaA, sprint, activityList)
	const poll = () => { return tPrework('Sprint Progress Polling', ics_2_5of6_PAaA.prework, sprint, activityList) }
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_2_6of6_PAaA.prework, sprint, activityList) }
	const quizExpectations = () => { return tQuizExpectations(sprint)}
	const recap = () => { return tRecap(ics_2_5of6_PAaA.agenda.slice(1))}
	const quiz = () => { return tQuiz(sprint) }

	return 	completeDeck(slideDeck, [ poll, breakoutNumberingSystems, preworkNext, tLab, recap, quizExpectations, quiz ])
}

// Session 6 of 6: Friday
const ics_2_6of6_PAaA = {
	'prework': [
		'Complete through activity 15 and be working on 16', '',
		'Be prepared for Lab',
		`All sprint ${sprint} assignments are due Sunday!` ],
	'announcements':[
		`All sprint ${sprint} assignments are due Sunday!`],
	'agenda':[
		'Lab']
}
export const ics_2_6of6 = () => {
	const finalReviewActivityListAndAssignments = () => { return basicSlide(
		`Final sprint ${sprint} activity list and assignment Q&A`, [`Any sprint ${sprint} activity list or assignment questions?`]) }
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_2_6of6_PAaA, sprint, activityList)
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_3_1of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ finalReviewActivityListAndAssignments, preworkNext, tLab ])
}

// One slide deck for each of the 6 potential class sessions. 
export const ics_2_slidedeck = [ ics_2_1of6, ics_2_2of6, ics_2_3of6, ics_2_4of6, ics_2_5of6, ics_2_6of6 ] 