import React from 'react'

import { learningObjectivesIntro, initialPost, closing, estimated } from './AL00000Sprint00'
import { softwareEngineeringSprint5Link } from '../Activities/ClassProduct'

import { oreillyPlaylistSE, externalLink } from '../DataAndAPIs/Links'
import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { scrum, productBacklog, sprintPlanning, sprintBacklog, userStory, userStoryTemplate, iNVEST, done } from '../DataAndAPIs/Links'

import { calendarLink } from '../DataAndAPIs/Links'
import { sprintEndDateWithoutTime } from '../CalendarAndSchedule/SprintDates'

export const al44000Sprint05 = () => {
	const sprint = 5
	return ( <div>
		<h5>Sprint 5: {getModuleDescription(sprint-1)}</h5>

		<p>Welcome to sprint 5. It’s time to delivery valuable features to real product that have real customers. We will do this by
		utilizing Agile Software Development Lifecycle (SDLC) Rolls including Scrum Master and Product Owner. We will groom our {productBacklog()} and  
		complete {sprintPlanning()} to deliver our immutable {sprintBacklog()}. We will use {scrum()}, {userStory()} ({userStoryTemplate()}), {done()}, 
		and the {iNVEST()} philosophy.</p>

		<p>We will also learn about software maintenance and very quickly come to understand that since Agile development has us shipping 
		software to customers every few weeks, nearly all of the work we do in scrum is essentially maintenance.</p>

		{learningObjectivesIntro(sprint)}
		<ul style={{ listStyleType: 'square' }}>
			<li>Groom our {productBacklog()} and complete {sprintPlanning()} to create our immutable {sprintBacklog()}</li>
			<li>Set up our development workflow so that supports local development and testing, hosted testing, and production environments</li>
			<li>Explain why change is inevitable for software systems and describe the processes of software evolution</li>
			<li>Understand Software Maintenance and why almost everything we do in Agile is Software Maintenance</li>
		</ul>

		{list44000Sprint05(sprint)}
		{closing(sprint)}
	</div> )
}

export const list44000Sprint05 = (sprint) => {
	const softwareMaintenanceAndSupport = () => { return externalLink('Software Maintenance and Support', 'https://lewiseducation.blob.core.windows.net/software-engineering/software-maintenance-and-support.mp4') }

	return ( <div>
		<p>Below is our activities list which, according to our {calendarLink(sprint)}, needs to be completed by <em>{sprintEndDateWithoutTime(sprint-1)}</em>:</p>
		<ol>
			<li><em>Review sprint {sprint} assignments</em></li>
			<li><em>Submit Lab {sprint} - Planning</em> after completing {softwareEngineeringSprint5Link()} Requirements 0, 1, and 2 and </li>
			<li>Read Chapter 9 of Fox on Software Maintenance</li>
			<li>Review {softwareMaintenanceAndSupport()} lecture</li>
			{initialPost(sprint)}
			<li>Complete {softwareEngineeringSprint5Link()} Requirements 3 and 4 {estimated('6 hours capacity per team member')}</li>
			<li>Within {oreillyPlaylistSE()} scan “Web Development with Node and Express” chapters 5 through 15</li>
			<li><em>Submit all sprint {sprint} assignments by {sprintEndDateWithoutTime(sprint-1)}</em></li>
		</ol>
	</div> )
}

// Certified for SP25 on March 24, 2025



