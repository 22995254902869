import React from 'react'

import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { initialPost, standardActivities, standardActivitiesClosing, closing, estimated } from './AL00000Sprint00'
import { internalLink, oreillyPlaylistSE, productBacklog, sprintPlanning, userStory } from '../DataAndAPIs/Links'
import { healthRiskCalculatorTeamWebLink } from '../Activities/HealthRiskCalculatorTeam'
import { gettingToKnowEachOthersTeamsLink } from '../Activities/WebGettingToKnowEachOthersTeams'

export const al44000Sprint04 = () => {
	let sprint = 4
	return ( <div>
		<h5>Sprint 4: {getModuleDescription(sprint-1)}</h5>

		<p>We are on a mission in sprint {sprint}. It will not be easy, but done right we will set ourselves us 
		on a sophisticated product that will be used by real users and can be added to your portfolio and resume.</p>

		<p>The concepts that we will need to understand in order to complete our mission are included below in our Learning Objectives:</p>
		<ul style={{listStyleType:'square'}}>
			<li>Utilize Agile Software Development Lifecycle (SDLC) Rolls including Scrum Master and Scrum Product Owner</li>
			<li>Demonstrate Requirements gathering through {sprintPlanning()}, {userStory()}(s), {productBacklog()}, and a Sprint Backlog</li>
			<li>Utilize our SaaS architecture including HTML, CSS, and JavaScript clients with server APIs in Node.js</li>
			<li>Understand Testing and Test-Driven Development</li>
		</ul>

		<p>Now on to the mission. The sprint {sprint} mission comes in two parts. First, you and your team will utilize  
		Scrum and our SaaS architecture to deliver {healthRiskCalculatorTeamWebLink()}. Second, you will start using your 
		product as a customer / user.</p>

		<p>Now that you have your mission, let’s get to work. You will need all of the tools and knowledge that you have
		learned in the first three sprints to complete this mission. Good luck!</p>
		
		{list44000Sprint04(sprint)}
		{closing(sprint)}
	</div> )
}

export const list44000Sprint04 = (sprint) => {
	const foxChapter8Lecture = () => { return internalLink('lecture', '/activity/fox-chapter-08') }
		const softwareTesting = () => { return internalLink('Software Testing', '/activity-oop/java-testing') }
	return ( <div>
		<p>Below is the activities list that will help us complete our sprint {sprint} mission:</p>
		<ol>
			{standardActivities(sprint, healthRiskCalculatorTeamWebLink(), oreillyPlaylistSE())}
			<li><em>Review sprint {sprint} assignments including Discussion {sprint}, Quiz {sprint}, Lab {sprint}, Reflection {sprint}, and Lab Demo</em></li>
			<li>Review {softwareTesting()} lecture</li>
			<li>Submit <em>Lab 1 - Planning by the end of the day Wednesday, March 5th</em></li>

			{initialPost(sprint)}
			<li>Complete {gettingToKnowEachOthersTeamsLink()}{estimated('4 hours')}</li>
			<li>Read Chapter 8 of Fox on Test-Driven Development and review the associated class {foxChapter8Lecture()}</li>
			{standardActivitiesClosing(sprint, healthRiskCalculatorTeamWebLink(), '6 hours per team member')}
		</ol>
	</div> )
}

// Certified for SP25 on February 25, 2025






