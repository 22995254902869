import { orderedListSlide, scrumProcess } from './SLSprint00'

export const insertInto = (destinationDeck, sourceDeck, atDestinationIndex) => {
	let returnSlideDeck = destinationDeck
	for (var insertSlideIndex = 0; insertSlideIndex < sourceDeck.length; insertSlideIndex++) {
		returnSlideDeck.splice(atDestinationIndex+insertSlideIndex, 0, sourceDeck[insertSlideIndex])
	}
	return returnSlideDeck
} 

export const sprintPlanningSlideDeck = (sprint) => {
	const intro = () => {
		return orderedListSlide('Sprint Planning', 
			`Goal: Each team member will **request** and commit to multiple User Stories that have a total story point 
			estimate approximately equal to their sprint ${sprint} capacity by utilizing the following steps:`, [
				'Reviewing Priorities and Roles',
				`Reviewing “Done” and sprint {sprint} Team Capacity`,
				`Grooming the Product Backlog... one Product Backlog per Product`, 
				`Completing Sprint ${sprint} Planning... one immutable Sprint ${sprint} Backlog per Team` ]
		)
	}
	const prioritiesAndRoles = () => {
		return orderedListSlide('Step 1: Priorities and Roles', 
			'Scrum priorities and associated roles include:', [
				'Customer & Product Owner',
				'Technology & Architect (optional)',
				'Process & Scrum Master' ])
	} 
	const done = () => {
		return orderedListSlide('Step 2: “Done” and Team Capacity', 
			'Update your team’s definition of “Done” by:', [
				'Verifying your definition of Done includes that stories are deployed and available to Demo in production',
				'Including at least one team specific item (i.e. “Demoed to Product Owner” or “Test in production”)',
				'Reviewing your definition of Done located in the “done.md” file in the root of your product repository',
				`Documenting your team’s sprint {sprint} capacity in story points` ])
	} 
	const productBacklogGrooming = () => {
		return orderedListSlide('Step 3: Product Backlog Grooming', 
			'Product Owner leads your team’s Product Backlog Grooming of stories so that the backlog includes:', [
				'Document team capacity in story points',
				`More stories than your team can get Done in sprint ${sprint}`,
				'Immutable unique identifiers for each story',
				'Stories in story format and that meet the SMART criteria',
				'Force ranked stories based on user value (prioritized stories if forced ranking is not possible)',
				'Story point estimates for each story based on “Done” and assuming that 1 story point ~ 1 hour effort',
				'Split stories that are too large to complete in a single sprint',
				'Split stories that need to be assigned to more than one team member',
				'Only a “small” percentage of spikes' ])
	}
	const productBacklogGroomingUpdate = () => {
		return orderedListSlide('Step 3a: Product Backlog Grooming Update', 
			'Product Owner leads your team’s Product Backlog Grooming Update by:', [
				`Copying not “Done” sprint ${sprint-1} stories back to the Product Backlog`,
				`Warning: Copy don’t move stories from the Sprint ${sprint-1} Backlog`,
				'Question: Will your team decide to re-estimate not “Done” stories as they are moved? (Answer: Yes)' ])
	}
	const planning = () => {
		return orderedListSlide('Step 4: Sprint Planning', 
			'Create your team’s Sprint Backlog by:', [
				'Team members requesting highest priority stories', 
				`Product Owner **Moves** requested highest priority stories to the Sprint ${sprint} Backlog`,
				'Assigning each story to a **single** team member',
				'Maintaining the force ranking of stories as they are moved to the Sprint Backlog',
				`Creating the team’s **immutable** Sprint ${sprint} Backlog`,
				'Documenting the Sprint Backlog including team capacity plus stories and story points committed',
				'Making commitments while recalling that it is okay to be wrong, but not okay to be ambiguous' ])
	}

	return [ intro, scrumProcess, prioritiesAndRoles, done, scrumProcess, productBacklogGrooming, productBacklogGroomingUpdate, 
		scrumProcess, planning ]
} 