import React from 'react'
import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { oreillyPlaylistSC, calendarLink, scheduleLink } from '../DataAndAPIs/Links'
import { sprintEndDateWithoutTime } from '../CalendarAndSchedule/SprintDates'
import { closing, learningObjectivesIntro, standardActivitiesClosing, initialPost, estimated } from './AL00000Sprint00'
import { lectureRecordingPlaylist } from './AL49200Sprint02'
import { capstoneSprint4Link } from '../Activities/ClassProduct'

export const al49200Sprint04 = () => {
	const sprint = 4
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>
		<p>Embrace our Software Engineering development team. You don’t have enough customers. Improve that by turning 
		your NG development team into new product users. If you don’t, it’s on you that you don’t have enough customers
		(users). If you do it well, you can get their product feedback as they are first learning the product and use 
		that feedback to guide our future development priorities.</p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Master sprint planning for ourselves and for the “NG” product delivery team</li>
			<li>Assist the Software Engineering team with Product Backlog Grooming in preparation for Sprint {sprint+1} Planning</li>
			<li>Fully understand MVP while staying laser focused on implementing your MVP by the end of sprint {sprint}</li>
			<li>Master sprint reviews (demos), retrospectives, and done</li>
		</ul>

		{list49200Sprint04(sprint)}
		{closing(sprint)}
	</div> )
}

export const list49200Sprint04 = (sprint) => {
	return ( <div>
		<p>A quick look at our {calendarLink(sprint)} and {scheduleLink(sprint)} tell us that Discussion {sprint}, 
		Writing Assignment {sprint}, Lab {sprint}, and Reflection {sprint} must be completed by 
		<em>{sprintEndDateWithoutTime(sprint-1)}</em>. The sprint {sprint} Activity list includes:</p>
		<ol>
			<li>Complete {capstoneSprint4Link()} Requirements 0, 1, and 2</li>
			<li>Review the sprint {sprint} planning {lectureRecordingPlaylist()}</li>

			<li>Within {oreillyPlaylistSC()} review and fully implement “Essential Scrum” chapters 19 through 22</li>
			{initialPost(sprint)}
			<li>Within {oreillyPlaylistSC()} rigorously read “Essential Scrum” chapters 14 through 18{estimated('2 hour')}</li>
			{standardActivitiesClosing(sprint, capstoneSprint4Link(),'14 hours per team member')} 
		</ol>
	</div> )
}

// Certified for SP25 on March 19, 2025




