
import React from 'react';
import StudyTableImage from './StudyTable2025Spring.png';

export const StudyTable = () => {
	return ( <div>
		<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
		<img src={StudyTableImage} alt='Study Table' width= '1024'/>
		</div>
	</div> )
}