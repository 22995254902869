
import { se_1_slidedeck } from './SL44000Sprint01'
import { se_2_slidedeck } from './SL44000Sprint02'
import { se_3_slidedeck } from './SL44000Sprint03'
import { se_4_slidedeck } from './SL44000Sprint04'
import { se_5_slidedeck } from './SL44000Sprint05'
import { tUnderConstruction } from './SL00000Sprint00'

const uCSD = [ tUnderConstruction ] // A uCSD is an array of one tUnderConstruction slide.
const uCM = [ uCSD, uCSD, uCSD, uCSD, uCSD, uCSD ] // A uCM is an array of 6 class sessions each containing a UCSDs.

// The se_slidedeck structure is a multiple dimensional array of slidesdecks consisting of 8 modules of 6 classes each. 
// Each slidedeck is an array of 1 to n slides. 
export const se_slidedeck = [ se_1_slidedeck, se_2_slidedeck, se_3_slidedeck, se_4_slidedeck, se_5_slidedeck, uCM, uCM, uCM ]