import React from 'react'

import { learningObjectivesIntro, activitiesListIntro, standardActivities, initialPost, standardActivitiesClosing, closing, estimated } from './AL00000Sprint00'
import { oreillyPlaylistSC, continuousIntegration, externalLink } from '../DataAndAPIs/Links'

import { gettingToKnowEachOthersTeamsLink } from '../Activities/WebGettingToKnowEachOthersTeams'
import { azureWebsiteLink } from '../Activities/AzureWebsite'

import { HTMLW3C, CSSW3C, JavaScriptW3C } from '../DataAndAPIs/Links'
import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { gettingToKnowEachOtherLink } from '../Activities/WebGettingToKnowEachOther'
import { capstoneSprint2Link } from '../Activities/ClassProduct'

export const lectureRecordingPlaylist = () => { return externalLink('lecture', 'https://lewisu.hosted.panopto.com/Panopto/Pages/Viewer.aspx?pid=50ad011a-766e-43e7-8ec4-ae2301373d46')}

export const celebrationOfScholarship = () => { return externalLink('Celebration of Scholarship', 'https://www.lewisu.edu/cos') }
export const celebrationOfScholarshipRegistration = () => { return externalLink('Celebration of Scholarship Registration', 'https://www.lewisu.edu/celebration-scholarship/gettingstarted.htm?mode=&id=research-presentations') }
export const al49200Sprint02 = () => {
	const sprint = 2
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>

		<p><em>Welcome back.</em> Now that we are comfortable with our teams and the new semester, it’s time to focus on enhancing our 
		product. Our first step in consistently delivering product enhancements to customers is to validate and improve our continuous 
		integration / continuous delivery (CI/CD). <em>CI/CD</em> is often much harder than it sounds. Done well, CI/CD allows a team to 
		consistenly release new functionality to development, test, and production environments without introducing defects. How will you 
		ensure your team is able to saftely implement, test, and demonstrate new functionality?</p>

		<p>The second step in consistely delivering product enhancements to costomers is to <em>have costomers that utilize
		the enhancements</em>. In order to attract customer, we need demonstrate the value of our new feature to costomers. 
		One place we will be showcasing these enhancements is {celebrationOfScholarship()}. Mark your calendar for the 
		afternoon of Thursday, April 24 where you and your team be sharing your product enhancements in a poster session. 
		The only requirement this sprint is that the Product Owner will need to complete the {celebrationOfScholarshipRegistration()}. 
		Note that registration closes Friday, February 14th. Note that your participatein {celebrationOfScholarship()} will 
		replace the our midterm.</p>

		<p>In addition, we will be recruiting our future product development team for the current Software Engineering class
		on Wednesday, February 19 at 11 am CT. This is also an opportunity to get new product users (customers) in addition to future
		develpers. Participatation in both events is required for the full team; however, the Product Owner for this sprint will 
		lead the completion of {celebrationOfScholarshipRegistration()} while the product owner for next sprint will lead the
		effort to recruit the future delivery team. 
		</p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Understand and sign up for Celebration of Scholarship (CoS)</li>
			<li>Prepare to recruit future developers from Software Engineering on February 19 at 11 am CT</li>
			<li>Understand Configuation Managment and Continuous Integration and Continuous Deployment (CI/CD)</li>
			<li>Implement, document, and demonstrate CI/CD for your product team’s development, test, and productions environments</li>
			<li>Implement, document, and demonstrate CI/CD for your customer’s access to test, and productions environments</li>
		</ul>

		{list49200Sprint02(sprint)}
		{closing(sprint)}
	</div> )
}

export const list49200Sprint02 = (sprint) => {
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, capstoneSprint2Link())}
			<li>Review our sprint 2 planning {lectureRecordingPlaylist()}</li>
			<li>Sign up for a {celebrationOfScholarshipRegistration()} and mark your calendar for the afternoon of Thursday, April 24</li>
			<li>Read {continuousIntegration()}</li>
			<li>Within {oreillyPlaylistSC()} read “Essential Scrum” chapters 7 through 10</li>

			<li>As needed review {HTMLW3C()}, {CSSW3C()}, and {JavaScriptW3C()} tutorials</li>
			<li>Individually complete {azureWebsiteLink()} to publish your {gettingToKnowEachOtherLink()} on Microsoft Azure{estimated('2 hours')}</li>
			{initialPost(sprint)}

			<li>Prepare to recruit your future development team from the Software Engineering class on Wednesday, February 19 at 11 am CT</li>
			<li>With your team complete {gettingToKnowEachOthersTeamsLink()}{estimated('3 hours')}</li> 
			<li>Within {oreillyPlaylistSC()} read “Essential Scrum” chapters 11 and 12</li>
			{standardActivitiesClosing(sprint, capstoneSprint2Link(), '8 hours per team member')}
		</ol>
	</div> )
}

// Certified for SP25 on February 2, 2025



