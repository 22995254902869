import { ics_1_slidedeck } from './SL20000Sprint01'
import { ics_2_slidedeck } from './SL20000Sprint02'
import { ics_3_slidedeck } from './SL20000Sprint03'
import { tUnderConstruction } from './SL00000Sprint00'

const uCSD = [ tUnderConstruction ] // A uCSD is an array of one tUnderConstruction slide.
const uCM = [ uCSD, uCSD, uCSD, uCSD, uCSD, uCSD ] // A uCM is an array of 6 class sessions each containing a UCSDs.

// The se_slidedeck structure is a multiple dimensional array of slidesdecks consisting of 8 modules of 6 potential 
// classes each. Each slidedeck is an array of 1 to n slides. 
export const ics_slidedeck = [ ics_1_slidedeck, ics_2_slidedeck, ics_3_slidedeck, uCM, uCM, uCM, uCM, uCM ]