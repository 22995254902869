
import { checklistAnnouncementsPreworkAndAgenda, completeDeck } from './SL00000Sprint00'
import { bulletListSlide } from './SLSprint00'

import { list49200Sprint01 } from '../ActivityLists/AL49200Sprint01'

import { martinLutherKingDay } from './SL20000Sprint01'
import { orderedListSlide } from './SLSprint00'

// Software Systems Capstone (SSC) sprint 1 global values.
const sprint = 1
const activityList = () => { return list49200Sprint01(sprint) }

// Session 1 of 2: Monday
export const ssc_1_1of2 = () => { return [ martinLutherKingDay ] } //{ return xyz_1_1of6(activityList, ssc_1_3of4_PreworkAnnouncementsAndAgenda.prework) }

// Session 2 of 2: Monday
const ssc_1_2of2_PAaA = {
	'prework':[
		'Complete Sprint 1 Planning', '',
		'Be prepared to complete Discussion 1', 
		'Be prepared for Lab' ],
	'announcements':[ 
		'Thank you for your support overcoming the holiday last Monday' ],
	'agenda':[ 
		'Final Scrum Team Review',
		'Sprint 1 Planning Q&A',
		'What Sprint is this for your product?',
		'Lab' ]
}
export const ssc_1_2of2 = () => { 
	const lab = () => { 
		return bulletListSlide('Lab', 
			'Let’s focus on completing our activities and assignments by:', [
			'Determining what do we need to do for Discussion 1 and “Getting to Know Each Other”?',
			'Reviewing your team’s Sprint 1 Backlog',
			'Deciding how we can best utilize our time today', 
			'Completing the most important activities and assignments together',
			'Identifing sprints 3 Scrum Master and Product Owner (no changes for sprint 2)',
			'Foreshadowing recruiting Software Engineering teams at the beginning of Sprint 3',
			'Foreshadowing Celebration of Scholarship in Sprint 5' ])
	}
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ssc_1_2of2_PAaA, sprint, activityList)
	const finalReviewOfScrumTeams = () => {
		return orderedListSlide('Final Scrum Team Review', 
			'Please take one final look at your scrum team name and team members.', [])
	}
	const sprintPlanningQA = () => {
		return orderedListSlide('Sprint Planning Q&A', 
			`Are you clear on what’s expected in sprint 1?`, [])
	}
	const sprintNumber = () => {
		return orderedListSlide('What Sprint Number?', 
			`What sprint number is this for your team? Or should we all be uniquely consistent?`, [])
	}

	return completeDeck(slideDeck, [finalReviewOfScrumTeams, sprintPlanningQA, sprintNumber, lab ])
}

