// BugBug: Convert this to sprint1_class_1... not sure if sprint1_class_1 exists yet. Likely obsolete xyz_1_2of6.

import { sprint1_class_2_PAaA, sprint1_class_2, sprint1_class_3_PAaA, sprint1_class_3 } from './SL00000Sprint00'
import { checklistAnnouncementsPreworkAndAgenda, completeDeck } from './SL00000Sprint00'
import { basicSlideWithLogo, breakoutStandard, tPrework, tQuiz, tQuizExpectations, tRecap } from './SLSprint00'

import { list44000Sprint01 } from '../ActivityLists/AL44000Sprint01'
import { martinLutherKingDay, lab_1_4of6 } from './SL20000Sprint01'
import { se_2_1of6_PAaA } from './SL44000Sprint02'

// Overview: This file contains 6 slide decks associated with Software Engineering (SE) module 1 / sprint 1. All 6 
//    slide decks are exported as se_1_slidedeck.

// Todo: Consider obsoleting xyz_1_2of6 and replacing with sprint1_class_1.
import { xyz_1_1of6 } from './SL00000Sprint01' 

// Software Engineering (SE) module 1 / sprint 1 global values.
const sprint = 1
const activityList = () => { return list44000Sprint01(sprint) }

// Session 1 of 6: Monday
// Todo: Update martinLutherKingDay to sprint1_class_1. May need to creat sprint1_class_1. Likely need to obsolete xyz_1_2of6.
export const se_1_1of6 = () => { return [ martinLutherKingDay ] }

// Session 2 of 6: Wednesday
const se_1_2of6_PAaA = {
	'prework':[
		'Review Welcome Message', 
		'Review Blended Learning',
		'Review Scrum',
		'Create Discord Account and set up Scrum Team Discord Server' ],
	'announcements':[ 
		'Friday’s class will be remote via Zoom ZZZ',
		'Community Hour is today at high noon'],
	'agenda':[ 
		'Progress Polling',
		'Introductions (continued)',
		'Sprint 1 Planning (continued)',
		'Scrum Team Breakout',
		'Prework for Next Class',
		'Recap' ]
}
export const se_1_2of6 = () => { return xyz_1_1of6(activityList, se_1_2of6_PAaA.prework) }

// Session 3 of 6: Friday
export const se_1_3of6 = () => { return sprint1_class_2(sprint, activityList, sprint1_class_2_PAaA, sprint1_class_3_PAaA.prework) }

// Session 4 of 6: Monday
export const se_1_4of6 = () => { return sprint1_class_3(sprint, activityList, sprint1_class_3_PAaA, se_1_6of6_PAaA.prework, lab_1_4of6) }

// Session 5 of 6: Wednesday
// Todo: This class session needs to be updated to reflect that in SP25 session 5 of 6 was canceled due to Exteded Community Hour. 
//     Preferrably the SDLC breakout would be moved back to this session instead of delayed until Friday.

const se_1_5of6 = () => { 
	const extendedCommunityHour = () => { return basicSlideWithLogo('Extended Community Hour', [ 'Extended Community Hour: No Classes 11 am to 1 pm' ]) }
	return [ extendedCommunityHour ]
}

// Sprint 1 session 6 of 6: Friday
const breakoutSDLCsName = 'SDLCs and the Virtuous Triangle'
export const breakoutSDLCs = () => { 
	return breakoutStandard(
		`Breakout: ${breakoutSDLCsName}`, 
		'In this breakout session your team will discuss', [
		'Software Development Life Cycles (SDLCS): Plan and Document, Iterative, and Agile',
		'The Virtuous Triangle... Could Saas on Cloud Computing and Frameworks and Tools be combined?',
		'SDLCs: Waterfall, Spiral, RUP',
		'Agile Manifesto',
		'SDLCs: Agile, Scrum, and Scaled Agile Framework (SAFe)',
		'Agile methodologies including Kanban, XP (formerly Extreme Programming), and Test-Driven Development' ])
}
const se_1_6of6_PAaA = {
	'prework':[
		'Make your initial Discussion 1 post',
		'Submit a sincere attempt at Quiz 1',
		'Complete through activity 12 prior to next class', 
		'Focus on reading Engineering Software as a Service Chapter 1 and the associated lecture (~2 hours)',
		'... Chapter 1 and the associated lecture are a BIG deal!',
		`Be prepared for “${breakoutSDLCsName}” breakout`],
	'announcements':[
		'All sprint 1 activities and assignments due Sunday!' ],
	'agenda':[
		'Sprint Progress Polling',
		`Breakout: ${breakoutSDLCsName}`,
		'Prework for Next Class',	
		'Quiz 1' ]
}
export const se_1_6of6 = () => { 
	const poll = () => { return tPrework('Sprint Progress Polling', se_1_6of6_PAaA.prework, sprint, activityList) }
	const preworkNext = () => { return tPrework('Prework For Next Class', se_2_1of6_PAaA.prework, sprint, activityList) }
	const quizExpectations = () => { return tQuizExpectations() }
	const quiz = () => { return tQuiz(sprint) }
	const recap = () => { return tRecap(se_1_6of6_PAaA.agenda.slice(1))}

	const slideDeck =  checklistAnnouncementsPreworkAndAgenda(se_1_6of6_PAaA, sprint, activityList)
	return completeDeck(slideDeck, [ poll, breakoutSDLCs, preworkNext, quizExpectations, quiz, recap ])
}

export const se_1_slidedeck = [ se_1_1of6, se_1_2of6, se_1_3of6, se_1_4of6, se_1_5of6, se_1_6of6 ] 