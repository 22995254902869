import { checklistAnnouncementsPreworkAndAgenda, completeDeck } from './SL00000Sprint00'
import { xyz_1_1of6 } from './SL00000Sprint01'
import { tPrework, tQuizExpectations, tQuiz, bulletListSlide, breakoutStandard, tRecap } from './SLSprint00'
import { basicSlideWithLogo } from './SLSprint00'

import { list20000Sprint01 } from '../ActivityLists/AL20000Sprint01'
import { ics_2_1of6_PAaA } from './SL20000Sprint02'

import { sprint1_class_2_PAaA, sprint1_class_2, sprint1_class_3_PAaA, sprint1_class_3 } from './SL00000Sprint00'
import { xyz_n_6of6_PAaA, xyz_n_6of6 } from './SL00000Sprint00'

// Introduction to Computer Science (ICS) sprint 1 global values.
const sprint = 1
const activityList = () => { return list20000Sprint01(sprint) }

// Sprint 1 session 1 of 6: Monday
export const martinLutherKingDay = () => { return basicSlideWithLogo('Martin Luther King Day', [ 'Martin Luther King Day: No Classes' ]) }
export const ics_1_1of6 = () => { return [ martinLutherKingDay ] }

//  Concider: export const ics_1_1of6 = () => { return xyz_1_1of6(activityList, ics_1_2of6_PAaA.prework) }
// Todo: Consolidate xyz_1_1of6 and sprint11_class_2... the are mostly the same. The main issue is that sometime 
// session 1 is a holiday and sometimes it is session 4 depending on the semester. 

// Session 2 of 6: Wednesday
const ics_1_2of6_PAaA = {
	'prework':[
		'Review Welcome Message', 
		'Review Blended Learning',
		'Review Scrum', 
		'Create Discord Account and set up Scrum Team Discord Server' ],
	'announcements':[ 
		'Friday’s class will be remote via Zoom' ],
	'agenda':[ 
		'Progress Polling',
		'Introductions (continued)',
		'Sprint 1 Planning (continued)',
		'Scrum Team Breakout',
		'Prework for Next Class',
		'Recap' ]
}
export const ics_1_2of6 = () =>  { return xyz_1_1of6(activityList, ics_1_2of6_PAaA.prework) }

// Session 3 of 6: Friday
export const ics_1_3of6 = () => { return sprint1_class_2(sprint, activityList, sprint1_class_2_PAaA, sprint1_class_3_PAaA.prework) }

const lab = () => {
	return bulletListSlide('Programming Together (Lab)', 
		'Let’s use our knowledge of Directories and Files to help each other set up a directory for this class and for tool-of-the-trade-screenshots by:', [
		'Quick review of graphical directory and file management tools', 
		'Launching File Explorer or Finder',
		'Launching PowerShell or Terminal', 
		'Executing pwd', 
		'Executing cd, ls, ls -l, ls -a, and clear',
		'Utilizing mkdir and rmdir' ])
}

// Session 4 of 6: Monday
export const lab_1_4of6 = () => { return bulletListSlide('Discussion 1, Lab, and Programming Together', 
	'Let’s focus on completing our activities and assignments by:', [
		'Discussing Wednesday Extended Commuity Hour... and if it impacts our schedule',
		'Previewing “Hello World Plus (with Pictures) for the Web” tutorial',
		'Discussing “Building an Introductions Website with GitHub Pages”', 
		'Completing Discussion 1' ])
}
// Session 4 of 6: Monday
export const ics_1_4of6 = () => { return sprint1_class_3(sprint, activityList, sprint1_class_3_PAaA, ics_1_5of6_PAaA.prework, lab_1_4of6) }

// Session 5 of 6: Wednesday
const ics_1_5of6_PAaA = {
	'prework':[
		'Make your initial Discussion 1 post',
		'Complete through activity 12 prior to next class', '',
		'Be prepared for “File Systems, Directories, and Files” Breakout',
		'Be prepared for Programming Together with “Directories and Files”',
		'Be prepared for Quiz 1'],
	'announcements':[
		'Your initial Discussion 1 post was due Monday',
		'All sprint 1 activities and assignments due Sunday'],
	'agenda':[
		'Sprint Progress Polling',
		'Breakout: File Systems, Directories, and Files',
		'Programming Together with “Directories and Files”', 
		'Prework for Next Class',
		'Quiz 1']
}
export const ics_1_5of6 = () => { 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_1_5of6_PAaA, sprint, activityList)
	const poll = () => { return tPrework('Sprint Progress Polling', ics_1_5of6_PAaA.prework, sprint, activityList) }
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_1_6of6_PAaA.prework, sprint, activityList) }
	const recap = () => { return tRecap(ics_1_5of6_PAaA.agenda)}
	const quiz = () => { return tQuiz(sprint) }

	const breakoutFileSystemsDirectoriesAndFile = () => {
		return breakoutStandard(
			'Breakout: File Systems, Directories, and Files', 
			'In this breakout session on File Systems, Directories, and Files (including Dale chapter 11 content) your team will discuss:', [
				'File Systems, Directories, and Hidden Directories... Do hidden folders keep information safe? ',
				'Files, Hidden Files, File Types, and File Extensions... How do we see hidden files and file extensions?', 
				'How would we utilize graphical and command line tools to view and update File Systems?',
				'What would be a good place to store files for this class? Why? See lab question.', 
				'What would be examples of questionable places to store files for this class? Why? See lab question.' ])
	}
	const breakoutOptimalLocationForClassFiles = () => {
		return breakoutStandard(
			'Breakout: Where should “epogue” store his files for class?', 
			'Explain why the “/users/epogue/lewis/cpsc-20000/sprint-1/hello-world” is better than any of the other locations for “epogue” to store his file for a class named “cpsc-20000”:', [
				'/users/epogue/lewis/cpsc-20000/sprint-1/hello-world',
				'./lewis/cpsc-2000/sprint-1/hello-world', 
				'/Users/epogue/Desktop/lewis/sprint-1/hello-world',
				'/Users/epogue/Documents/lewis/sprint-1/hello-world', 
				'./Recents',
				'/Users/epogue/lewis/cpsc 2000/sprint 1/helloworld',
				'/lewis/sprint-1/hello-world' ])
	}

	return completeDeck(slideDeck, [poll, breakoutFileSystemsDirectoriesAndFile, breakoutOptimalLocationForClassFiles, lab, tQuizExpectations, quiz, recap, preworkNext])
}

// Session 6 of 6: Friday
const ics_1_6of6_PAaA = xyz_n_6of6_PAaA('Complete activity 13', sprint)
export const ics_1_6of6 = () => { return  xyz_n_6of6(sprint, ics_1_6of6_PAaA, ics_2_1of6_PAaA.prework, activityList) }

// One slide deck for each of the 6 potential class sessions. 
export const ics_1_slidedeck = [ ics_1_1of6, ics_1_2of6, ics_1_3of6, ics_1_4of6, ics_1_5of6, ics_1_6of6 ] 
