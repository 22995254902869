import React from 'react'
import { oreillyPlaylistSC } from '../DataAndAPIs/Links'

import { learningObjectivesIntro, initialPost, standardActivitiesClosing, closing } from './AL00000Sprint00'

import { lectureRecordingPlaylist } from './AL49200Sprint02'
import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { capstoneSprint5Link } from '../Activities/ClassProduct'
import { calendarLink, scheduleLink } from '../DataAndAPIs/Links'
import { sprintEndDateWithoutTime } from '../CalendarAndSchedule/SprintDates'

export const al49200Sprint05 = () => {
	const sprint = 5
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>
		<p>Stay focused on delivering the most valuable stories for our customers (users) while at the same
		time integrating our NG (Next Generation) so they can also deliver customer enhancements.</p>

		<p>Be sure to remind the NG team to stay focused on delivering stories to the production environment that 
		add the most value for customers, and that being “done” with a something small and delivering it to production 
		is <em>immensely</em> more valuable than being “almost done” with something great.</p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Master sprint planning</li>
			<li>Coach your NG team so that they successfully complete sprint planning</li>
			<li>Master sprint reviews (demos) and sprint retrospectives as you complete them for sprint {sprint-1}</li>
			<li>Master ”done” and keep it in mind at all times while you are working on features</li>
		</ul>

		{list49200Sprint05(sprint)}
		{closing(sprint)}
	</div> )
}

export const list49200Sprint05 = (sprint) => {
	return ( <div>
		<p>Review our {calendarLink(sprint)} and {scheduleLink(sprint)} and plan your time so that you can complete
		Lab {sprint} - Planning in the first three days of the sprint and be able to submit the remaining assignments 
		by <em>{sprintEndDateWithoutTime(sprint-1)}</em>. The sprint {sprint} activities list includes:</p>
		<ol>
			<li><em>Review sprint {sprint} assignments including Lab - Planning, Discussion, Writing Assignment, Lab - Execution, and Reflection</em></li>
			<li>Complete {capstoneSprint5Link()} Requirements 0, 1, and 2</li>
			<li>Review the sprint {sprint} planning {lectureRecordingPlaylist()}</li>

			<li>Within {oreillyPlaylistSC()} review “Essential Scrum” looking for topics that your team can focus on improving</li>
			{initialPost(sprint)}
			{standardActivitiesClosing(sprint, capstoneSprint5Link(),'14 hours per team member')} 
		</ol>
	</div> )
}

// Certified for SP25 on March 23, 2025
// Todo: in FA24 move “free writing assignment” from Spring Break to a different sprint that has a holiday.


