import { xyz_n_1of6_prework_list, xyz_n_1of6, completeDeck, checklistAnnouncementsPreworkAndAgenda, tLab } from './SL00000Sprint00'

import { xyz_n_1of6_lists } from './SL00000Sprint00'

import { sprintDemosIntro, sprintDemos, demoAssignment, reviewDemoSchedule, tExamExpectations, tExam } from './SLSprint00'
import { tPrework, bulletListSlide, discussionBreakout, submissionPercentage, orderedListSlide, breakoutStandard } from './SLSprint00'

import { list20000Sprint03 } from '../ActivityLists/AL20000Sprint03'
import { list20000Sprint04 } from '../ActivityLists/AL20000Sprint04'

import { tReviewDemoSchedule } from './SL00000Sprint00'
import { basicSlide } from './SLSprint00'

// Sprint 4 Introduction to Computer Science (ICS) values.
const sprint = 4
const activityListPrevious = () => { return list20000Sprint03(sprint-1) }
const activityList = () => { return list20000Sprint04(sprint) }

// Todo: Review https://stackoverflow.com/questions/1300242/passing-a-function-with-parameters-as-a-parameter
// Todo: Create xyz_n_1of6 version 2 that includes optional null (or default_announcements) announcements and makes prework_list 
// and agenda_list optionally null (or default_prework_list and default_agenda_list)

// Session 1 of 6: Monday
export const xyz_4_1of6PlanningExpectations = () => {
	return orderedListSlide('Planning', `Sprint ${sprint} Expectations:`, [
		'Similar to previous sprints with Discussion, Quiz, Lab, and Reflection',
		'Similar in that we will complete our scrum team Discussion Board breakout and Lab on Wednesday',
		'Different in that we will have our in-person midterm exam on Wednesday next week',
		`And different that we will have sprint ${sprint-1} Demos and Retrospective next Monday` ])
}
const ics_4_1of6_PAaA = xyz_n_1of6_lists(sprint)
export const ics_4_1of6 = () => { 
	const slides = xyz_n_1of6(sprint, ics_4_1of6_PAaA.prework, activityListPrevious, ics_4_1of6_PAaA.agenda, ics_4_2of6_lists.prework, activityList, xyz_4_1of6PlanningExpectations) 
	slides.splice(4, 0, classProjects)
	return slides
}

// Add https://witty-sand-028b04910.2.azurestaticapps.net/index.html
export const ics_4_1of6_prework_list = xyz_n_1of6_prework_list(sprint)

export const classProjects = () => {
	return bulletListSlide('Class Projects', 
		'Class Projects snf Sprints 4 through 8:', [
		'When I first came to Lewis, I thought class projects were a cop-out', 
		'... I was 100% wrong, wrong, wrong', 
		'I have worked to continuously improved my classes by giving more time to class projects',
		'This semester you will have 30+ hours to on a class project of **your** choice',
		'Sprint 7 & sprint 8 Expectation: ~30 hours in Sprint 7 plus ~2 hours in Sprint 8',
		'My suggestion? Do something that will make you proud... and that you could show to a perspective employer',
		'What will you do?' ])
}

export const substituteSprintPlanning = () => {
	return orderedListSlide('Planning', `Sprint ${sprint} Expectations:`, [
		'Similar to previous sprints with Discussion, Quiz, Lab, and Reflection',
		'Similar in that we will complete our scrum team Discussion Board breakout and Lab on Wednesday',
		`Similar in that we will have sprint ${sprint-1} Demos and Retrospective on Friday`,
		'Different in that we will have our in-person midterm exam on Wednesday next week',
		'Different in that we will have three weeks and have Spring Break the last week of the sprint' ])
}

// Session 2 of 6: Wednesday
const ics_4_2of6_lists = {
	'prework':[
		'Complete through activity 5 prior to next class', '',
		`Be prepared Discussion Board ${sprint}`,
		'Be prepared for Lab & Programming Together' ],
	'announcements':[ 
		'Registration is coming... do you know what you want to take?',
		'Wednesday, March 12 is our midterm Exam your in person attendance is required', ],
	'agenda':[
		`Discussion Board ${sprint} as a scrum team`,
		'Review Demo Schedule for Next Class',
		'Prework for Next Class',
		'Lab & Programming Together' ]
}
export const ics_4_2of6 = () => {
	const discussion4Breakout = () => { return discussionBreakout(sprint) } 
	const programmingTogether = () => {
		return bulletListSlide('Lab & Programming Together', 
			'Let’s commit to focusing on our Lab assignment by:', [
			'Start MatchMakerLite tutorial by implementing a README.md and LICENSE file in GitHub', 
			'Adding a index.html file', 
			'Pushing the updated files to GitHub',
			'Finally, let’s do the same thing for MatchMaker' ])
	}
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_4_4of6_PAaA.prework, sprint, activityList) }

	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_4_2of6_lists, sprint, activityList)
	return completeDeck(slideDeck, [ discussion4Breakout, reviewDemoSchedule, preworkNext, programmingTogether ])
}

// Session 3 of 6: Friday
const ics_4_3of6_PAaA = {
	'prework': [
		'Complete through activity 10 prior to next class', '',
		`Be prepared for sprint ${sprint-1} demos and retrospectives`,
		'Those scheduled to demo please be a couple of minutes early to class' ],
	'announcements':[
		'Your in-person attendance is required next Wednesday for you midterm exam' ],
	'agenda':[
		`Sprint ${sprint-1} Demos`,
		`Sprint ${sprint-1} Retrospective`,
		`Breakout for Sprint ${sprint-1} Retrospective`,
		'Prework for Next Class',
		'Lab & Programming Together' ]
}

export const ics_4_3of6 = () => { 
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_4_3of6_PAaA, sprint, activityList)
	const metrics = () => {
		return basicSlide(`Sprint ${sprint-1} Metrics`, [
			'What is Bob Parsons Rule #9?', '',
			`Let’s take a minute and review our Sprint ${sprint-1} Submission Percentage class metric.` ])
	}
	const metricsSubmissionPercentage = () => {
		return submissionPercentage([
			{ name: 'Discussion', due:21, submitted:20 },
			{ name: 'Quiz', due:21, submitted:20 },
			{ name: 'Lab', due:21, submitted:20 },
			{ name: 'Reflection', due: 21, submitted: 19 }])
	}
	const retrospective = () => {
		return orderedListSlide('Class Retrospective',
			'Feedback from Assignments & Reflections', [
			'Good submission percentage',
			'Treats for perfect 100% submission percentage',
			'All assignments are graded and posted', 
			'Thank you for your reflection comments',
			'Several comments about having success with starting early and less cramming at the last minute' ])
	}
	const retrospectiveBreakout = () => {
		return orderedListSlide('Breakout Session for Team Retrospective', 
			'As a scrum team consider:', [
			`How does your team feel about sprint ${sprint-1} now that it is over`,
			`What could be done to make sprint ${sprint-1} or the class overall better or more manageable`,
			'What improvements should we make as a class, team, or individual going forward' ])
	}
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_4_4of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ sprintDemosIntro, sprintDemos, tReviewDemoSchedule, demoAssignment, metrics, 
		metricsSubmissionPercentage, retrospective, retrospectiveBreakout, preworkNext, tLab ])
}

// Session 4 of 6: Monday
const breakoutComputingComponentsName = 'Computing Components'
const breakoutComputingComponents = () => { 
	return breakoutStandard( 
		`Breakout: ${breakoutComputingComponentsName}`, 
		`In this breakout session on our ${breakoutComputingComponentsName} reading and lecture your team will discuss:`, [
		'Computing Components',
		'Stored Program plus Von Neumann Architecture',
		'Ram and Rom plus Secondary Storage',
		'Embedded Systems',
		'Parallel Architectures plus Classes of Parallel Hardware' ])
}

const ics_4_4of6_PAaA = {
	'prework':[
		'Complete through activity 9 prior to next class', '',
		`Be prepared for sprint ${sprint-1} demos and retrospectives`,
		'Those scheduled to demo please be a couple of minutes early to class' ],
	'announcements':[ 
		'**Wednesday is our midterm Exam your in person attendance is required', '',
		'Registration is coming',
		'Are you interested in the 4+1 program?' ],
	'agenda':[
		`${breakoutComputingComponentsName} Breakout`,
		'Prework for Next Class',
		'Lab & Programming Together' ]
}

export const metricsAndBobParsonsQuote = () => {
	return basicSlide(`Sprint ${sprint-1} Metrics`, [
		'Bob Parsons Rule #9: Measure everything of significance. I swear this is true. Anything that is measured and watched, improves. -- Bob Parsons', '',
		`Let’s take a minute and review our Sprint ${sprint-1} Submission Percentage class metric.` ])
}
export const ics_4_4of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_4_4of6_PAaA, sprint, activityList)
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_4_5of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ breakoutComputingComponents, preworkNext, tLab ])
}

// Session 5 of 6: Wednesday (midterm Exam)
const ics_4_5of6_PAaA = {
	'prework':[
		'**Be prepared to take you midterm exam in person on Wednesday' ],
	'announcements':[ 
		`All sprint ${sprint} assignments due Sunday!` ],
	'agenda':[
		'Midterm Exam' ],
}
export const ics_4_5of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_4_5of6_PAaA, sprint, activityList)
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_4_6of6_PAaA.prework, sprint, activityList) }
	return completeDeck(slideDeck, [ preworkNext, tExamExpectations, tExam ])
}

// Session 6 of 6: Friday
const ics_4_6of6_PAaA = {
	'prework':[
		'Complete through activity 13 prior to next class', '',
		'Be prepared for Lab & Programming together' ],
	'announcements':[ 
		`All sprint ${sprint} assignments due Sunday, March 23!` ],
	'agenda':[
		'Final Activity List & Assignment Review',
		'Prework for Next Class',
		'Lab and Programming Together' ]
}
export const ics_4_6of6 = () => {
	const slideDeck = checklistAnnouncementsPreworkAndAgenda(ics_4_6of6_PAaA, sprint, activityList)
	const finalReviewActivityListAndAssignments = () => { return basicSlide(
		`Final sprint ${sprint} activity list and assignment Q&A`, [`Any sprint ${sprint} activity list or assignment questions?`]) }
	const preworkNext = () => { return tPrework('Prework For Next Class', ics_5_1of6_PAaA.prework, sprint, activityList) }

	return completeDeck(slideDeck, [ finalReviewActivityListAndAssignments,preworkNext, tLab ])
}

const ics_5_1of6_PAaA = {
	'prework':[
		`All sprint ${sprint} assignments due Sunday!`, '',
		`Be prepared for sprint ${sprint+1} planning` ],
	'announcements':[ 
		'' ],
	'agenda':[
		'' ]
}

