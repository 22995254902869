import React from 'react'
import { estimated } from './AL00000Sprint00'

import { oreillyPlaylistSC } from '../DataAndAPIs/Links'
import { activitiesListIntro, closing, learningObjectivesIntro, standardActivities, standardActivitiesClosing, initialPost } from './AL00000Sprint00'
import { lectureRecordingPlaylist } from './AL49200Sprint02'
import { getModuleDescription } from '../DataAndAPIs/ClassSections'
import { capstoneSprint3Link } from '../Activities/ClassProduct'

export const al49200Sprint03 = () => {
	const sprint = 3
	return ( <div>
		<h5>Sprint {sprint}: {getModuleDescription(sprint-1)}</h5>
		<p><em>Welcome to sprint 3.</em> The future of your product depends on your team successfully recruiting the 
		next generation of developers. Specifically you will be recruiting a team from the Software 
		Engineering class. They will be working with you the second half of the semester. Your team will be 
		responsible for transitioning all product responsibilities and assesAt the end of the 
		semester you will complete the transition of the product to them and a subset of their team will continue
		the legacy as they they take your place next semester in the Fall 2025 Capstone Class. The first step will
		be <em>presenting your product to the Software Engineering students on Wednesday, February 19
		at 11 am in room AS-104-A.</em></p> 
		<p>Oh yes, and we still need to deliver value to our customers by delivering important functionality to
		them in the production envirnment. To help set the direction for the product and make sure that we are working
		on the most important stories, we will also be consolidatig our individual Minimum Viable Product (MVP) visions
		into a single product MVP that needs to be deliever by the end of sprint 5. </p>

		{learningObjectivesIntro(sprint)}
		<ul style={{listStyleType:'square'}}>
			<li>Practice marketing your product by recruiting the next generation product delivery team</li>
			<li>Consolidate your team’s MVP and focus on implementing the most valuable stories to deliver it</li>
			<li>Reflect, plan, and improve</li>
			<li>Execute, test, and deliver</li>
		</ul>

		{list49200Sprint03(sprint)}
		{closing(sprint)}
	</div> )
}

export const list49200Sprint03 = (sprint) => {
	return ( <div>
		{activitiesListIntro(sprint)}
		<ol>
			{standardActivities(sprint, capstoneSprint3Link(), oreillyPlaylistSC(), false)}
			<li>Review our sprint 3 planning {lectureRecordingPlaylist()}</li>
			<li>Within {oreillyPlaylistSC()} scan “Essential Scrum” chapters 13 through 18{estimated('1 hour maximum')}</li>
			{initialPost(sprint)}

			<li>Within {oreillyPlaylistSC()} read “Essential Scrum” chapters 19 through 22{estimated('2 hours')}</li>
			{standardActivitiesClosing(sprint, capstoneSprint3Link(),'12 hours per team member')}
		</ol>
	</div> )
}

// Todo: Review Lab 3 Question 7 to make sure that the link to the team MVP is provided.
// Todo: Review Lab 3 Question 3 to make it more challenging and verifiable to justify the 10 points. 

// Certified for SP25 on February 17, 2025



